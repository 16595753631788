import { Divider, Grid, TextareaAutosize, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Theme from '../../../../css/Theme'
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CardFinder from './CardFinder'
import appSubject from '../../../../subjects/AppSubject'
import DragAndDropList from '../../../../shared/Components/DragAndDrop/DragAndDropList'
import dragAndDropSubject from '../../../../shared/Components/DragAndDrop/DragAndDropSubject'

/**
 * Local textareaAutosize customization
 */
const ThemedTextareaAutosize = styled(TextareaAutosize)(() => ({
    width: '100%',
    borderRadius: '5px',
    border: '2px solid white',
    background: 'transparent',
    '&::placeholder': {
        padding: '2%',
        textOverflow: 'ellipsis !important',
        color: 'white !important',
        fontFamily: Theme.typography.fontFamily,
        fontSize: 'medium',
    },
}))

/**
 * Render two components, one shared by all the creating/modifing pages and another acording to the selected page.
 * @param {String} page Selected page identifier.
 * @returns two components, one shared by all the creating/modifing page options and another acording to the selected page.
 */
export default function CreateNew() {
    const [addedCards, setAddedCards] = useState(appSubject.addedCardsToSection)

    const navigateOnViewer = () => {
        appSubject.updateSelectedPage('homeSections', true)
    }


    const onAddedCardsUpdate = () => {
        setAddedCards(appSubject.addedCardsToSection)
    }

    // const showScheduledButton = addedCards.some(card => new Date(card.activeUntil) > new Date());


    useEffect(() => {
        appSubject.addObserver(onAddedCardsUpdate)
        return () => {
            appSubject.removeObserver(onAddedCardsUpdate)
        }
    }, [])

    const handleAddCards = (element) => {
        appSubject.handleAddCardsToSection(element)
        dragAndDropSubject.addItemsToList(element)
        navigateOnControls('createSection', false)
    }

    const navigateOnControls = (pageName, cleanSelectedItem) => {
        appSubject.updateSelectedPage(pageName, cleanSelectedItem)
    }


    const handleRemoveCards = (element) => {
        appSubject.handleRemoveCardsToSection(element)
        dragAndDropSubject.removeItemFromList(element)
        navigateOnControls('createSection', false)
    }

    const handleEditCard = (element) => {
        appSubject.updateSelectedPage('scheduleCard', false, element)
        dragAndDropSubject.addItemsToList(element)
    }

    return (
        <>
            <Grid container wrap="nowrap" item sx={{ m: '5px 0' }} direction="row" justifyContent="flex-start">
                <Grid container item xs={1}>
                    <ArrowForwardIosIcon
                        onClick={navigateOnViewer}
                        sx={{
                            fontSize: 'x-large',
                            transform: 'rotate(180deg)',
                            color: Theme.palette.darkerGray.main,
                            cursor: 'pointer',
                        }}
                    />
                </Grid>
                <Grid container item xs={4}>
                    <ThemedTextareaAutosize
                        minRows={5}
                        maxRows={5}
                        sx={{ resize: 'none' }}
                        defaultValue={appSubject.newSection.description}
                        onChange={(e) => (appSubject.newSection.description = e.target.value)}
                        placeholder="Descripcion larga (200 caracteres max)"></ThemedTextareaAutosize>
                </Grid>
            </Grid>
            <Divider
                sx={{
                    width: '90%',
                    height: '1px',
                    backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0),rgb(255, 255, 255), rgba(0, 0, 0, 0))',
                    margin: '20px',
                }}
                variant="middle"
            />
            <Grid container item xs={1}>
                <Typography
                    sx={{
                        color: Theme.palette.darkerGray.main,
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'start',
                        margin: '2%',
                    }}>
                    Administrar CARDS
                </Typography>
            </Grid>
            <Grid
                container
                item
                xs={9}
                direction="row"
                wrap="nowrap"
                sx={{ height: '100%', width: '100%' }}
                justifyContent="space-around">
                <Grid
                    item
                    xs={4}
                    container
                    direction={'column'}
                    sx={{
                        backgroundColor: 'white',
                        width: '100%',
                        overflow: 'auto',
                        alignItems: 'center',
                    }}>
                    <DragAndDropList
                        defaultList={addedCards}
                        showDeleteButton
                        deleteButtonHandler={(i) => {
                            handleRemoveCards(addedCards[i])
                        }}
                        onChange={(orderList) => {
                            appSubject.saveOrderSection(orderList)
                        }}
                        renderOption={(addedCards) => addedCards.id + '. ' + addedCards.title}
                        optionValue={(addedCards, newOrder) => {
                            addedCards.weight = newOrder
                        }}
                        showEditButton
                        editButtonHandler={(i) => {
                            handleEditCard(addedCards[i])
                        }}
                    />
                </Grid>
                <CardFinder handleCards={handleAddCards} />
            </Grid>
        </>
    )
}
