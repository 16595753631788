import React from 'react'
import styled from '@emotion/styled'
import { AccordionDetails, Button, Grid, Tooltip, Typography } from '@mui/material'
import appSubject from '../../../../subjects/AppSubject'

const TypographyOnDetails = styled(Typography)(() => ({
    color: 'white',
    fontSize: '13px',
    margin: '5px',
}))

const HomeDetails = ({ infoObj }) => {
    const handleModify = () => {
        appSubject.onSelectItemToModify(infoObj)
    }

    const fechaActual = new Date();

    const arrayFiltrado = infoObj.cards.filter((objeto) => {
        if (objeto.activeUntil) {
            const fechaActiveUntil = new Date(objeto.activeUntil);
            return fechaActiveUntil > fechaActual;
        }
        return true; // Mantener objetos sin activeUntil
    });

    const handleDelete = (id) => {
        appSubject.deleteSection(id)
    }


    return (
        <AccordionDetails sx={{ padding: '0 1%', marginBottom: '3px' }}>
            <Grid
                container
                direction="row"
                sx={{ backgroundColor: '#666666', padding: '10px', borderRadius: '0 0 10px 10px' }}>
                <Grid container item xs={6} direction="row">
                    <Grid item xs={8} container direction={'column'} sx={{ backgroundColor: 'white', borderRadius: '10px' }}>
                        {infoObj.cards.map((elem, index) => (
                            !elem.activeUntil || new Date(elem.activeUntil) > new Date() ? (
                                <Grid
                                    container
                                    item
                                    key={index}
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    sx={{ margin: '5px', width: '95%', border: '1px solid #ffa33e', borderRadius: '5px' }}
                                >
                                    <Grid item xs={9}>
                                        <Tooltip title={elem.title} disableInteractive>
                                            <Typography
                                                noWrap
                                                sx={{ width: '100%', fontWeight: '600', fontSize: '15px', textTransform: 'capitalize' }}
                                            >
                                                {elem.title.toLowerCase()}
                                            </Typography>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            ) : null
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={6} direction={'column'} sx={{ height: '100%' }} spacing={1}>
                    <Grid container item xs={9} direction={'column'} sx={{ height: '100%' }}>
                        <Grid item xs={9}>
                            <TypographyOnDetails sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
                                {infoObj.name}
                            </TypographyOnDetails>
                            <TypographyOnDetails>({arrayFiltrado.length} card/s)</TypographyOnDetails>
                        </Grid>
                        <Grid item xs={3}>
                            <TypographyOnDetails>{infoObj.description}</TypographyOnDetails>
                        </Grid>
                    </Grid>
                    <Grid item container xs={3} direction={'row'}>
                        <Grid item xs={6}>
                            <Button
                                onClick={handleModify}
                                sx={{ backgroundColor: 'transparent', color: 'red', border: '2px solid red', width: '120px' }}>
                                MODIFICAR
                            </Button>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                onClick={() => {
                                    handleDelete(infoObj.id)
                                }}
                                sx={{
                                    backgroundColor: 'transparent',
                                    color: '#cecece',
                                    border: '2px solid #cecece',
                                    width: '120px',
                                    visibility: infoObj.order === 1 && 'hidden',
                                }}>
                                ELIMINAR
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AccordionDetails>
    )
}

export default HomeDetails
