// EditButtonSection.js
import React from 'react';
import { Grid } from '@mui/material';
import { ThemedButton } from "../../shared/Components/CustomButton/CustomButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const EditorHeader = ({ buttonText = "Editar Sede", buttonColor = "red" }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Grid item xs={1} sx={{ width: "100%" }}>
        <ThemedButton
          background="transparent"
          border={`2px solid ${buttonColor}`}
          color={buttonColor}
        >
          {buttonText}
        </ThemedButton>
      </Grid>
      <Grid item xs={1} justifyContent={"center"}>
        <KeyboardArrowDownIcon
          sx={{ color: "white", height: "100%", fontSize: "60px" }}
        />
      </Grid>
    </Grid>
  );
};

export default EditorHeader;
