import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import { Grid, Button, InputAdornment, OutlinedInput } from '@mui/material'
import theme, { lighterGray } from '../../../css/Theme'

/**
 *
 * @param {any} props Any prop a TextField could accept, plus style custom props for backgroundColor
 * @returns a custom TextField Component
 */
const CustomTextField = (props) => {
    const CustomInput = styled(OutlinedInput)({
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderWidth: '5px',
                borderStyle: 'solid',
                borderColor: 'transparent',
            },
            '&:hover ': {
                borderColor: 'transparent',
            },
            '&.Mui-focused ': {
                borderColor: 'transparent',
            },
        },
    })

    return (
        <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            sx={{
                background: props.backgroundcolor || theme.palette.gradient.main,
                borderRadius: props.borderRadius || '10px',
                height: props.height || '100%',
                width: '100%',
            }}>
            <CustomInput
                {...props}
                size="small"
                sx={{
                    background: props.background || lighterGray,

                    borderRadius: '7px',
                    width: `calc(100% - ${props.borderWidth || '4px'})`,
                    height: `calc(100% - ${props.borderWidth || '4px'})`,
                    paddingRight: '2%',
                }}
                endAdornment={
                    props.searchbutton && (
                        <InputAdornment position="end">
                            <Button edge="end" sx={{ background: props.searchIconBackground || theme.palette.gradient.main, minWidth: '50%', padding: '1px' }}>
                                <SearchIcon color={props.searchIconColor || 'white'}
                                    sx={{ transform: 'rotate(90deg)', fontSize: 'x-large' }} />
                            </Button>
                        </InputAdornment>
                    )
                }
            />
        </Grid>
    )
}
export default CustomTextField
