import styled from '@emotion/styled'
import { Select } from '@mui/material'
import Theme from '../../../css/Theme'

const CustomSelect = (props) => {
  const ThemedSelect = styled(Select)(({ border }) => ({
    width: '100%',
    height: props.height || '100%',
    border: border || '2px solid white',
    borderRadius: '10px',
    paddingLeft: '5px',
    color: Theme.palette.darkerGray.main,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSelect-outlined': {
      padding: '3px',
    },
  }));

  return (
    <ThemedSelect 
      {...props} 
      MenuProps={{ sx: { maxHeight: '250px' } }} 
      border={props.border}
    />
  );
};
export default CustomSelect
