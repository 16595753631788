import { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import theme from "../../../css/Theme";
import calendarSubject from "../../../subjects/CalendarSubject";

export const ActivityList = ({ filter }) => {

    const [activities, setActivities] = useState([]);

    useEffect(() => {
        calendarSubject.getAllActivities().then(res => { setActivities(res.activities) })
    }, [])

    return (
        <>
            <Grid
                container
                item
                wrap="nowrap"
                direction="column"
                xs={12}
                sx={{
                    overflowY: "auto",
                    background: theme.palette.primary.main,
                    height: "100%",
                }}
            >
                <Grid
                    justifyContent={'center'}
                    marginBottom={'10px'}
                    item
                    container
                    direction="row"
                >
                    <Grid
                        justifyContent={'center'}
                        item
                        container
                        direction="row"
                        xs={3}
                    >
                        <Typography
                            color={theme.palette.black.main}
                            noWrap
                            sx={{ fontWeight: "bolder" }}
                            fontSize={'1.2rem'}
                        >
                            ID
                        </Typography>
                    </Grid>
                    <Grid
                        justifyContent={'center'}
                        item
                        container
                        direction="row"
                        xs={9}
                    >
                        <Typography
                            color={theme.palette.black.main}
                            noWrap
                            sx={{ fontWeight: "bolder" }}
                            fontSize={'1.2rem'}
                        >
                            Nombre
                        </Typography>
                    </Grid>

                </Grid>

                {activities.filter(({ name }) => filter === '' || name.toLowerCase().includes(filter.toLowerCase())).map((activity, i) => {
                    const backgroundColor = i % 2 === 0 ? '#e6e7e6' : 'white';
                    return <ActivitySummary key={i} id={`${activity.name}-${i}`} value={activity} backgroundColor={backgroundColor} />;
                })}
            </Grid>
        </>
    );
};
const ActivitySummary = ({ value, backgroundColor }) => {

    return (
        <>
            <Grid direction="row">
                <Grid
                    item
                    container
                    direction="row"
                    minHeight={'2.5rem'}
                    justifyContent={'center'}
                    alignContent={'center'}
                    alignItems={'center'}
                    sx={{ backgroundColor: backgroundColor }}
                >
                    <Grid
                        justifyContent={'center'}
                        item
                        container
                        direction="row"
                        xs={3}
                    >
                        <Typography
                            color={theme.palette.darkGray.main}
                            noWrap
                            sx={{ fontWeight: "bolder", marginLeft: '1rem' }}
                        >
                            {value.id}
                        </Typography>
                    </Grid>
                    <Grid
                        justifyContent={'center'}
                        item
                        container
                        direction="row"
                        xs={9}
                        sx={{ borderLeft: 'solid black 1px' }}
                    >
                        <Typography
                            color={theme.palette.darkGray.main}
                            noWrap
                            sx={{ fontWeight: "bold" }}
                        >
                            {value.name}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
