import genericAxiosClient from '../axios/GenericAxiosClient'

class CalendarService {
    baseUrl = '/admin/planning'

    getClubList() {
        return genericAxiosClient.get(this.baseUrl + '/clubs').then((response) => {
            return response.data
        })
    }

    getActivitiesList(id) {
        return genericAxiosClient.get(`${this.baseUrl}/activities/${id ?? ''}`).then((response) => {
            return response.data
        })
    }

    getCoachList() {
        return genericAxiosClient.get(this.baseUrl + '/coaches').then((response) => {
            return response.data
        })
    }

    getSalonsList() {
        return genericAxiosClient.get(this.baseUrl + '/salons').then((response) => { return response.data })
    }

    getClasses(clubId, activityId, coaches, startDate, endDate) {
        let daysOfWeek = [
            { name: 'Monday', n: 1 },
            { name: 'Tuesday', n: 2 },
            { name: 'Wednesday', n: 3 },
            { name: 'Thursday', n: 4 },
            { name: 'Friday', n: 5 },
            { name: 'Saturday', n: 6 },
            { name: 'Sunday', n: 0 },
        ]

        const url = new URLSearchParams()
        url.append('club_id', clubId)
        url.append('activity_id', activityId)
        url.append('start_date', startDate)
        url.append('end_date', endDate)

        return genericAxiosClient.get(this.baseUrl + '?' + url.toString()).then((response) => {
            return response.data.map((element) => {
                let coachName = coaches.find((elem) => elem.id === element.coachId)
                if (element.days) {
                    let daysArray = element.days.split(',')
                    element.days = daysArray.map((element) => {
                        let found = daysOfWeek.find((day) => day.name.toUpperCase() === element.toUpperCase())
                        if (found) return found.n
                        else return null
                    })
                }
                element.start = new Date(
                    new Date(element.startAt).toLocaleString('en-us', {
                        timeZone: 'UTC',
                    })
                )
                element.end = new Date(
                    new Date(element.endAt).toLocaleString('en-us', {
                        timeZone: 'UTC',
                    })
                )
                if (coachName) element.title = coachName.name + ' ' + coachName.surname
                else element.title = 'Staff Megatlon'
                return element
            })
        })
    }

    postClass(request) {
        return genericAxiosClient
            .post(this.baseUrl, JSON.stringify(request))
            .then((response) => {
                return response.data
            })
            .catch((err) => {
                console.log(err)
                throw err
            })
    }

    putClass(id, request) {
        return genericAxiosClient
            .put(this.baseUrl + '/' + id, JSON.stringify(request))
            .then((response) => {
                return response.data
            })
            .catch((err) => {
                console.log(err)
                throw err
            })
    }

    delete(id, entireSerie) {
        const url = new URLSearchParams()
        entireSerie && url.append('delete_serie', entireSerie)
        return genericAxiosClient
            .delete(this.baseUrl + '/' + id + '?' + url.toString())
            .then((response) => {
                return response.data
            })
            .catch((err) => {
                console.log(err)
                throw err
            })
    }
}
const calendarService = new CalendarService()
export default calendarService
