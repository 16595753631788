import React from 'react';
import { Grid, Typography } from '@mui/material';

const InputText = ({ label = "Nombre:", gridProps = {}, typographyProps = {} }) => {
  return (
    <Grid container item xs={10} {...gridProps}>
      <Typography {...typographyProps}>{label}</Typography>
    </Grid>
  );
};

export default InputText;
