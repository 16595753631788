
import { Divider, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import theme from '../../../css/Theme'
import { ActivityList } from './ActivityList'

const ActivityViewer = () => {
    const [filter, setFilter] = useState('')

    const handleChange = (e) => {
        setFilter(e.target.value)
    }

    return (
        <>
            <Grid container item xs={1} direction="row" justifyContent={'flex-start'} sx={{ mt: 1 }}>
                <Grid container item xs={7} direction="column">
                    <Typography>Configuración</Typography>
                    <Typography variant="h4">Actividades</Typography>
                </Grid>
                <Grid container item xs={4} direction="column" alignItems={'flex-end'}>
                    {(
                        <Grid item xs={6}>
                            <input
                                placeholder={"Nombre de categoria"}
                                onChange={handleChange}
                                value={filter}
                                style={{
                                    borderRadius: '10px',
                                    border: '2px black solid',
                                    outline: 'none',
                                    padding: '10px'
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={0.25}>
                <Divider orientation="horizontal" color={theme.palette.primary.main} />
            </Grid>
            <ActivityList filter={filter} />
        </>
    )
}
export default ActivityViewer
