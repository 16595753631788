export class CSVNotificationBuilder {
    columns = {}
    columnsArr = []
    argsFns = []
    separator = ','
    title = ''
    body = ''
    rows = []

    constructor(title, body) {
        this.title = title
        this.body = body
    }

    reset() {
        this.columns = {}
        this.columnsArr = []
        this.argsFns = []
        this.separator = ','
        this.title = ''
        this.body = ''
    }

    addColumn({ name, validValues, validationFn, index, optional }) {
        this.columns[name] = { validValues, validationFn, index }
        this.columnsArr.push({ validValues, validationFn: validationFn ?? null, name, optional: optional ?? false })
    }

    addArgFn(fn) {
        this.argsFns.push(fn)
    }

    getRegexForColumns() {
        let regexStr = `^`


        this.columnsArr.forEach(({ name }, i) => {
            regexStr += `(${name.toLowerCase()})`

            if (i === this.columnsArr.length - 1)
                return

            regexStr += i === 0 ? '(.)' : '\\2'
        })
        regexStr += '$'

        return new RegExp(regexStr, 'g')
    }

    makeRows(text) {
        text = text.replaceAll('\r', '\n')
        const firstRow = text.split('\n')[0].toLocaleLowerCase().replaceAll(' ', '').trim();
        this._validateFirstRow(firstRow)

        const separator = text.slice(this.columnsArr[0].name.length, this.columnsArr[0].name.length + 1);
        if (!(/\r?\n/.test(text))) {
            let regex = new RegExp(separator, 'g');
            let count = 0
            text = text.replace(regex, (match, offset) => {
                count++
                return count % this.columns.length === 0 ? '\n' : match
            })
        }

        let rows = text.split(/\n/).filter(row => row.trim() !== "");
        rows = rows.slice(1, rows.length).map(row => row.split(separator))

        this._validateEmptyFields(rows)
        this._validateInvalidValues(rows)
        rows = this._generateArguments(rows)

        this.rows = rows
    }

    getFile(name) {
        let csvContent = [['Title', 'Body', 'Type', 'Target', 'SocioId', 'DNI', 'Email', 'Arguments'], ...this.rows.map(row => [this.title, this.body, row[0].trim(), row[1], row[2], row[3], row[4], row[5]])]

        csvContent = csvContent.map(row => row.map(field => {
            return field.includes(',') ? `'${field}'` : field
        }))

        const newFile = new File([csvContent.map(row => row.join(',')).join('\n')], name, { type: 'text/csv' })

        return newFile

    }

    _generateArguments(rows) {

        const targetIndex = this.columns['target'].index
        const typeIndex = this.columns['type'].index
        const socioIndex = this.columns['socioid'].index
        const dniIndex = this.columns['dni'].index
        const emailIndex = this.columns['email'].index

        return rows.map((row, i) => {

            let args = {}

            this.argsFns.forEach(fn => {
                args = { ...args, ...fn(row) }
            })

            return [row[typeIndex], row[targetIndex], row[socioIndex], row[dniIndex], row[emailIndex], JSON.stringify(args)]
        })
    }

    _validateFirstRow(text) {
        const regex = this.getRegexForColumns()

        if (!regex.test(text)) {
            throw new Error(`Las columnas deben tener el siguiente orden: ${this.columnsArr.map(({ name }) => name).join(', ')}`)
        }
    }

    _validateInvalidValues(rows) {
        rows.forEach((columnValues, k) => {
            columnValues.forEach((value, i) => {
                const { validValues, validationFn, name, optional } = this.columnsArr[i]

                if (optional && value.trim() === '')
                    return

                if (validValues && !validValues.includes(value))
                    throw new Error(`Error en la fila ${k}, la columna ${name} no cumple con los valores adecuados`);

                if (validationFn !== undefined)
                    return

                const { valid, error } = validationFn(value, columnValues)

                if (!valid)
                    throw new Error(`Error en la fila ${k}, ${error}`);
            })
        })
    }

    _validateEmptyFields(rows) {
        const invalidRows = rows.filter(row => row.length !== this.columnsArr.length);
        if (invalidRows.length > 0) {
            throw new Error('Error de formato: Hay filas con datos faltantes.');
        }
    }
}
