import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import UploadImg from '../../../shared/Components/UploadImgComponent/UploadImg'
import Theme from '../../../css/Theme'
import styled from '@emotion/styled'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Video from './Video'
import onDemandSubject from '../../../subjects/OnDemandSubject'
import Section from './Section'
import Program from './Program'
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

/**
 * Local typography customization
 */
const ThemedTypography = styled(Typography)(() => ({
    color: Theme.palette.darkerGray.main,
}))

/**
 * Render two components, one shared by all the creating/modifing pages and another acording to the selected page.
 * @param {String} page Selected page identifier.
 * @returns two components, one shared by all the creating/modifing page options and another acording to the selected page.
 */
export default function CreateNew({ page }) {
    const navigateOnViewer = () => {
        if (onDemandSubject.selectedPage === 'createVideo') onDemandSubject.updateSelectedPage('videos')
        if (onDemandSubject.selectedPage === 'createProgram') onDemandSubject.updateSelectedPage('programs')
        if (onDemandSubject.selectedPage === 'createSection') onDemandSubject.updateSelectedPage('sections')
        onDemandSubject.addedContentToSection = []
        onDemandSubject.addedVideosToPrograms = []
    }

    /**
     * Takes into account if the user is creating or modifying. If the user is modifying, the inputs are filled up with the appropiate information.
     * @returns an object model with all the information needed to complete the form component, according to the selected page.
     */
    const getCorrectData = () => {
        switch (page) {
            case 'createVideo': {
                if (onDemandSubject.newVideo) {
                    return onDemandSubject.newVideo
                }
                break
            }
            case 'createSection': {
                if (onDemandSubject.newSection) {
                    return onDemandSubject.newSection
                }
                break
            }
            case 'createProgram': {
                if (onDemandSubject.newProgram) {
                    return onDemandSubject.newProgram
                }
                break
            }
            default:
                return
        }
    }
    /**
     * Renders a component according to the page variable.
     * @returns switches the rendered compoenent according to the selected page.
     */
    const renderPage = () => {
        switch (page) {
            case 'createVideo': {
                return <Video />
            }
            case 'createSection': {
                return <Section />
            }
            case 'createProgram': {
                return <Program />
            }
            default:
                return <></>
        }
    }

    const getImgName = (keyword) => {
        let found = getCorrectData().viewTypes.find((element) => element.viewType === keyword)
        if (found) return found.image
    }

    return (
        <>
            <Grid container item xs={1} direction="row" sx={{ m: '5px 0' }}>
                <ArrowForwardIosIcon
                    onClick={navigateOnViewer}
                    sx={{
                        fontSize: 'x-large',
                        transform: 'rotate(180deg)',
                        color: Theme.palette.darkerGray.main,
                        cursor: 'pointer',
                    }}
                />
                <ThemedTypography sx={{ fontWeight: 'bold', textAlign: 'start', marginLeft: '2%' }}>Imagenes</ThemedTypography>
            </Grid>

            <Grid item container xs={4} sx={{ height: '100%' }} justifyContent="center">
                {onDemandSubject.selectedPage !== 'createSection' ? (
                    <>
                        <Grid item container xs={4} direction="column" justifyContent={'center'} alignItems="center">
                            <UploadImg
                                onChange={(file) => onDemandSubject.updateSelectedContentViewType('preview', file)}
                                value={getCorrectData().previewImage}
                                infoImg={{ format: 'JPG', resolution: '428 X 250' }}
                            />
                            <ThemedTypography sx={{ fontWeight: 'bold', marginTop: '2%' }}>Preview</ThemedTypography>
                        </Grid>
                        <Grid item container xs={4} direction="column" justifyContent={'center'} alignItems="center">
                            <UploadImg
                                onChange={(file) => onDemandSubject.updateSelectedContentViewType('standard', file)}
                                value={getImgName('standard')}
                                infoImg={{ format: 'JPG', resolution: '171 x 155' }}
                            />
                            <ThemedTypography sx={{ fontWeight: 'bold', marginTop: '2%' }}>Standard</ThemedTypography>
                        </Grid>

                        <Grid item container xs={4} direction="column" justifyContent={'center'} alignItems="center">
                            <UploadImg
                                onChange={(file) => onDemandSubject.updateSelectedContentViewType('featured', file)}
                                value={getImgName('featured')}
                                infoImg={{ format: 'PNG', resolution: '167 X 160' }}
                            />
                            <ThemedTypography sx={{ fontWeight: 'bold', marginTop: '2%' }}>Destacada</ThemedTypography>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item container xs={4} direction="column" justifyContent={'center'} alignItems="center">
                            <UploadImg
                                onChange={(file) => onDemandSubject.updateSelectedContentViewType('principal', file)}
                                value={getImgName('principal')}
                                infoImg={{ format: 'JPG', resolution: '428 X 250' }}
                            />
                            <ThemedTypography sx={{ fontWeight: 'bold', marginTop: '2%' }}>Principal</ThemedTypography>
                        </Grid>
                    </>
                )}
            </Grid>

            {onDemandSubject.selectedPage !== 'createSection' && (
                <Grid style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Grid item xs={10} style={{ marginTop: "20px" }}>
                        <CKEditor
                            editor={Editor}
                            style={{ width: "800px", display: "block", margin: "0 auto" }}
                            data={getCorrectData().shortDescription ? getCorrectData().shortDescription : ''}
                            onChange={(event, editor) => {
                                getCorrectData().shortDescription = editor.getData().replaceAll('color:#000000;', '');

                            }}
                        />
                    </Grid>
                    <Grid item xs={10} style={{ marginTop: "20px" }}>
                        <CKEditor
                            editor={Editor}
                            style={{ width: "800px", display: "block", margin: "0 auto" }}
                            data={getCorrectData().longDescription ? getCorrectData().longDescription : ''}
                            onChange={(event, editor) => {
                                getCorrectData().longDescription = editor.getData().replaceAll('color:#000000;', '');

                            }}
                        />
                    </Grid>
                </Grid>
            )}

            <Divider
                sx={{
                    width: '90%',
                    height: '1px',
                    backgroundImage: 'linear-gradient(to right, rgba(0, 0, 0, 0),rgb(255, 255, 255), rgba(0, 0, 0, 0))',
                    margin: '20px',
                }}
                variant="middle"
            />
            <Grid item container xs={6} justifyContent={'space-around'}>
                {renderPage()}
            </Grid>
        </>
    )
}
