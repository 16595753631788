/* import { ReactComponent as HomeIcon } from '../../../img/homeIcon.svg'
import { ReactComponent as ExercicesIcon } from '../../../img/exercicesIcon.svg'
import { ReactComponent as VenuesIcon } from '../../../img/venuesIcon.svg'
import { ReactComponent as UsersIcon } from '../../../img/usersIcon.svg'
import { ReactComponent as ProfileIcon } from '../../../img/profileIcon.svg'
import { ReactComponent as ConfigIcon } from '../../../img/configIcon.svg'*/
import { ReactComponent as CalendarIcon } from '../../../img/calendarIcon.svg'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import { ReactComponent as PlayIcon } from '../../../img/playIcon.svg'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import HomeIcon from '@mui/icons-material/Home'
import * as React from 'react'
import ConfigIcon from '@mui/icons-material/ManageAccounts';

class MenuItems {
  menuItems = [
    /* {
    text: 'Home Page',
    icon: <HomeIcon />,
    subMenu: [],
  },
  {
    text: 'Entrenamientos',
    icon: <ExercicesIcon />,
    subMenu: [
      {
        text: 'Ejercicios',
        link: '/home',
      },
      {
        text: 'Programas',
        link: '/home',
      },
      {
        text: 'Sesiones',
        link: '/home',
      },
      {
        text: 'Colectivas',
        link: '/home',
      },
    ],
  }, */
    {
      text: 'Planificación',
      icon: <CalendarIcon />,
      scope: 'all',
      subMenu: [
        {
          text: 'Calendario',
          link: '/planificacion/calendario',
        },
      {
        text: 'Reserva de clases',
        link: '/clases/reservar',
      }/*,
      {
        text: 'Prog. de clases',
        link: '/home',
      },
      {
        text: 'Sesión del día',
        link: '/home',
      },*/,
      ],
    },
    {
      text: 'Video',
      icon: <PlayIcon />,
      scope: 'admin',
      subMenu: [
        {
          text: 'On Demand',
          link: '/video/ondemand',
        },
        /*       {
        text: 'Streaming',
        link: '/home',
      }, */
      ],
    },
    {
      text: 'Notificaciones',
      icon: <NotificationsNoneIcon />,
      scope: 'admin',
      subMenu: [
        {
          text: 'Push',
          link: '/notificaciones/push',
        },
      ],
    },
    /* {
    text: 'Sedes',
    icon: <VenuesIcon />,
    subMenu: [
      {
        text: 'Club',
        link: '/home',
      },
    ],
  },
  {
    text: 'Usuarios',
    icon: <UsersIcon />,
    subMenu: [
      {
        text: 'Clientes',
        link: '/usuarios/clientes',
      },
      {
        text: 'Coaches',
        link: '/usuarios/coaches',
      },
      {
        text: 'Personal',
        link: '/usuarios/personal',
      },
    ],
  },
  {
    text: 'Perfil',
    icon: <ProfileIcon />,
    subMenu: [],
  },
  {
    text: 'Configuración',
    icon: <ConfigIcon />,
    subMenu: [],
  }, */
    {
      text: 'App',
      icon: <HomeIcon />,
      scope: 'admin',
      subMenu: [
        {
          text: 'Home',
          link: '/app/home',
        },
        {
          text: 'Cards',
          link: '/app/cards',
        },
      ],
    },
    {
      text: 'Configuracion',
      icon: <ConfigIcon />,
      scope: 'admin',
      subMenu: [
        {
          text: 'Salones',
          link: '/app/salons',
        },
        {
          text: 'Sedes',
          link: '/app/sedes',
        }
        ,
        {
          text: 'Actividades',
          link: '/app/actividades',
        }
      ]
    },
    {
      text: 'Logout',
      scope: 'all',
      icon: <LogoutRoundedIcon />,
      subMenu: [],
    },
  ]

  getItemsByScope = (clubId) => {
    return this.menuItems.filter((element) => {
      if (clubId !== 0) {
        return element.scope === 'all'
      } else {
        return element
      }
    })
  }
}

const menuItems = new MenuItems()
export default menuItems
