import genericAxiosClient from '../axios/GenericAxiosClient'

class AppService {
  baseUrl = '/admin/home'

  /**
   * Get Home section list
   * @return {Promise<Object[]>}
   */
  getHomeSectionList(fields) {
    let url = new URLSearchParams()
    if (fields) {
      let params = Object.keys(fields)
      params.forEach((element) => {
        url.append(element, fields[element].toString())
      })
    }

    return genericAxiosClient.get(this.baseUrl + '/sections?' + url.toString()).then((response) => {
      return response.data.map((element) => {
        if (element.action) element.action = JSON.parse(element.action)
        return element
      })
    })
  }

  /**
   * Post Home section
   * @param homeSectionRequest
   * @return {Promise<Object>}
   */
  postHomeSection(homeSectionRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/sections', JSON.stringify(homeSectionRequest))
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Put Home section
   * @param id
   * @param homeSectionRequest
   * @return {Promise<Object>}
   */
  putHomeSection(id, homeSectionRequest) {
    return genericAxiosClient
      .put(this.baseUrl + '/sections/' + id, JSON.stringify(homeSectionRequest))
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }
  /**
   * Delete Home section
   * @param id
   * @returns {Promise<Object>}
   */
  deleteHomeSection(id) {
    return genericAxiosClient
      .delete(this.baseUrl + '/sections/' + id)
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Get Home card list
   * @return {Promise<Object[]>}
   */
  getHomeCardList(fields) {
    let url = new URLSearchParams()
    if (fields) {
      let params = Object.keys(fields)
      params.forEach((element) => {
        url.append(element, fields[element].toString())
      })
    }

    return genericAxiosClient.get(this.baseUrl + '/cards?' + url.toString()).then((response) => {
      const data = response.data
      return data.map((element) => {
        element.action = JSON.parse(element.action)
        return element
      })
    })
  }

  /**
   * Post Home card
   * @param homeCardRequest
   * @return {Promise<Object>}
   */
  postHomeCard(homeCardRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/cards', JSON.stringify(homeCardRequest))
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Post Home card
   * @param homeCardRequest
   * @return {Promise<Object>}
   */
  async postHomeCardScheduled(homeCardRequest) {
    return genericAxiosClient
      .post(this.baseUrl + '/cards/scheduled', JSON.stringify(homeCardRequest))
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Put Home card
   * @param id
   * @param homeCardRequest
   * @return {Promise<Object>}
   */
  putHomeCard(id, homeCardRequest) {
    return genericAxiosClient
      .put(this.baseUrl + '/cards/' + id, JSON.stringify(homeCardRequest))
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }
  /**
   * Delete Home card
   * @param id
   * @returns {Promise<Object>}
   */
  deleteHomeCard(id) {
    return genericAxiosClient
      .delete(this.baseUrl + '/cards/' + id)
      .then((response) => {
        return response.data
      })
      .catch((err) => {
        console.log(err)
        throw err
      })
  }

  /**
   * Order Home section
   * @param newOrder
   * @returns
   */
  reorderHomeSection(newOrder) {
    return genericAxiosClient.put(this.baseUrl + '/sections/reorder', newOrder).catch((err) => {
      console.log(err)
      throw err
    })
  }

  saveCardImage(id, type, file) {
    const formData = new FormData()
    formData.append('image', file)
    return genericAxiosClient.post(`${this.baseUrl}/cards/${type}/${id}`, formData).catch((err) => {
      console.log(err)
      throw err
    })
  }

  saveSectionImage(id, type, file) {
    const formData = new FormData()
    formData.append('image', file)
    return genericAxiosClient.post(`${this.baseUrl}/sections/${type}/${id}`, formData).catch((err) => {
      throw err
    })
  }

  saveClubImage(file){
    const formData = new FormData()
    formData.append('image', file)
    return genericAxiosClient.post(`/images`, formData).catch((err) => {
      throw err
    })
  }

  /**
   * Get Home card list
   * @return {Promise<Object[]>}
   */
  getSedesList(fields) {
    let url = new URLSearchParams()
    return genericAxiosClient.get(this.baseUrl + '/cards?' + url.toString()).then((response) => {
      const data = response.data
      return data.map((element) => {
        element.action = JSON.parse(element.action)
        return element
      })
    })
  }
}

const appService = new AppService()
export default appService
