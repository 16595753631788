import { Grid } from '@mui/material'
import ActivityViewer from '../../components/PushNotification/ActivityComponent/ActivityViewer'

const ActivityPage = () => {

  return (
    <Grid container justifyContent={'space-evenly'} sx={{ height: '90%' }}>
      <Grid
        container
        item
        wrap="nowrap"
        xs={10}
        direction={'column'}
        justifyContent={'flex-start'}
        sx={{
          border: '1px solid #cccccc',
          borderRadius: '15px',
          padding: '10px',
          paddingBottom: 0,
          ml: '40px',
          mt: '20px',
          height: '100%',
          overflowY: 'hidden',
          overflowX: 'hidden',
        }}>
        <ActivityViewer />
      </Grid>
    </Grid>
  )
}
export default ActivityPage