import React from 'react';
import { Grid } from '@mui/material';

const ErrorMessage = ({ message, marginTop, marginBottom }) => {
  return (
    <Grid item xs={1} sx={{ width: "100%", height: "22px", marginTop, marginBottom }}>
      <span style={{ fontSize: "9px", color: "red" }}>{message}</span>
    </Grid>
  );
};

export default ErrorMessage;
