import React, { useCallback, useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button, Grid, Typography } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import dragAndDropSubject from "./DragAndDropSubject";
import Theme from "../../../css/Theme";
import classes from "./onDemandControls.module.css";

/**
 *
 * @param {Array} List List of options
 * @param {Function} onChange handler to execute after pressing save changes botton
 * @param {} renderOption
 * @param {String} title
 * @returns
 */
export default function DragAndDropList({
    defaultList,
    onChange,
    renderOption,
    title,
    optionValue,
    showDeleteButton,
    deleteButtonHandler,
    showEditButton,
    editButtonHandler,
    showScheduledButton
}) {
    const [saveButton, showSaveButton] = useState(false);
    const [list_, setList] = useState([]);
    const ELEMENT_ID = "draggable-";

    let draggables = useRef([]);
    let containers = useRef([]);
    const newOrder = useRef([]);

    const onListUpdate = () => {
        setList([...dragAndDropSubject.list]);
    };

    useEffect(() => {
        dragAndDropSubject.addObserver(onListUpdate);
        return () => {
            dragAndDropSubject.cleanList();
            dragAndDropSubject.removeObserver(onListUpdate);
        };
    }, []);

    useEffect(() => {
        dragAndDropSubject.populateList(defaultList);
    }, [defaultList]);

    useEffect(() => {
        if (list_.length < 1 && defaultList.length !== 0)
            dragAndDropSubject.populateList(defaultList);
    }, [list_, defaultList]);

    const dragStartHandler = (draggable) => {
        draggable.classList.add("dragging");
    };

    const dragEndHandler = useCallback(
        (draggable) => {
            draggable.classList.remove("dragging");

            newOrder.current = [];
            for (let i = 1; i <= containers.current.children.length - 1; i++) {
                let innerText = containers.current.children.item(i).innerText;
                let found = list_.find((element) => {
                    let tieneMasDeUnEspacio = /\s{2,}/.test(element.title);
                    if (tieneMasDeUnEspacio) {
                        element.title = element.title.replace(/\s{2,}/g, ' ')
                    }
                    return renderOption(element) === innerText;
                });
                if (found) optionValue(found, i);
                newOrder.current.push(found);
            }
            showSaveButton(true);
        },
        [list_, optionValue, renderOption]
    );

    useEffect(() => {
        /**
         * The prevent default changes the pointer if we drag inside or outside of the container.
         * When the draggable element is draged under all of the elements(if) it will return undefined so it appends the element to the end of the container
         * When the draggable element is draged over one of the elements(else) it will position the dragged element on top(above) of the afterElement.
         */
        containers.current.addEventListener("dragover", (e) => {
            e.preventDefault();
            const afterElement = getDragAfterElement(e.clientY);
            const draggable = document.querySelector(".dragging");
            if (afterElement == null) {
                containers.current.appendChild(draggable);
            } else {
                containers.current.insertBefore(draggable, afterElement);
            }
        });
    }, []);

    useEffect(() => {
        draggables.current.forEach((draggable) => {
            if (draggable) {
                draggable.removeEventListener("dragstart", () =>
                    dragStartHandler(draggable)
                );
                draggable.removeEventListener("dragend", () =>
                    dragEndHandler(draggable)
                );
            }
        });

        draggables.current.forEach((draggable) => {
            if (draggable) {
                draggable.addEventListener("dragstart", () =>
                    dragStartHandler(draggable)
                );
                draggable.addEventListener("dragend", () => dragEndHandler(draggable));
            }
        });
    }, [list_, dragEndHandler]);
    /**
     * First, filters the array of draggables (draggable elements) and returns the array with the elements that are not currently being dragged.
     * Then, the reduce funtion loops thrugh the array and determines witch single element is the one that is dirctly after our mouse cursor based on the Y position of the mouse that we are passing by parameter.
     * Reduce takes 2 parameters: the value we are reducing down to(closest, it's the element that we are cloest to that is directly after our mouse cursor) and a child, that is each element of the array. The second param of the reduce funtions is the initial value of closest that will be a infinit negative number.
     * The getBoundingClientRect() is used to get the position of our box and see if the cursor is over or under, calculating from the middle of each box
     * @param {Number} y The y-coordinate of the mouse pointer.
     * @returns the element that is directly after our mouse position. And when the mouse position is under all of the elements it returns undefind.
     */
    function getDragAfterElement(y) {
        draggables.current.filter(
            (el) => el !== document.querySelector(".dragging")
        );
        let arr = draggables.current.reduce(
            (closest, child) => {
                if (child) {
                    const box = child.getBoundingClientRect();
                    const offset = y - box.top - box.height / 2;
                    if (offset < 0 && offset > closest.offset) {
                        return { offset: offset, element: child };
                    } else {
                        return closest;
                    }
                } else return closest;
            },
            { offset: Number.NEGATIVE_INFINITY }
        );

        return arr.element;
    }

    const onSaveHandler = () => {
        if (window.confirm("En caso de ser un banner, el orden reemplazara la card que este actualmente publicada")) {
            onChange(newOrder.current);
        }
        showSaveButton(false);
        dragAndDropSubject.cleanList();
    };

    const RenderedOption = (option, i) => {
        const card = list_[i];
        const showTimeIcon = new Date(card.activeSince) > new Date();

        return (
            <Grid
                container
                item
                justifyContent="space-between"
                wrap="nowrap"
                alignItems="center"
                ref={(option) => {
                    draggables.current[i] = option;
                }}
                id={ELEMENT_ID + i}
                draggable="true"
                className={classes.draggable}
                key={i}
                sx={{
                    borderColor: Theme.palette.lightOrange.main,
                    borderWidth: "1px",
                    borderStyle: "solid",
                    maxHeight: "35px",
                    padding: "3%",
                    fontSize: "small",
                    color: Theme.palette.darkGray.main,
                    fontWeight: "bold",
                    margin: "2px",
                }}
            >
                {showTimeIcon &&
                    <Grid
                        item
                        sx={{
                            visibility: showTimeIcon ? "visible" : "hidden",
                        }}
                        style={{ marginRight: card.title.length < 20 ? "-17px" : "5px" }}
                        xs={1}
                    >
                        <AccessTimeIcon style={{ color: "green", height: "15px" }} />
                    </Grid>
                }
                <Typography noWrap sx={{ my: 1 }}>
                    {option}
                </Typography>
                <Grid
                    container
                    item
                    sx={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    xs={1}
                >
                    <Grid
                        item
                        sx={{
                            visibility: showDeleteButton
                                ? !saveButton
                                    ? "visible"
                                    : "hidden"
                                : "hidden",
                            marginLeft: "-20px",
                            marginRight: "25px",
                        }}
                        xs={1}
                        onClick={
                            showDeleteButton
                                ? () => {
                                    deleteButtonHandler(i);
                                }
                                : () => { }
                        }
                    >
                        <HighlightOffIcon style={{ color: "red", cursor: "pointer", height: "21px" }} />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            visibility: showEditButton ? "visible" : "hidden",
                        }}
                        xs={1}
                        onClick={
                            showEditButton
                                ? () => {
                                    editButtonHandler(i);
                                }
                                : () => { }
                        }
                    >
                        <EditIcon style={{ color: "blue", cursor: "pointer", height: "21px", marginLeft: "-4px" }} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    /**
     *Renders all the sections sorted by drag and drop witch changes their  order position.
     * @returns a component.
     */
    const sortSection = () => {
        return (
            <>
                {list_
                    .filter((elem, i) => {
                        let rendered = renderOption(elem);
                        if (rendered) return rendered;
                        else return null;
                    })
                    .map((element, i) => {
                        return RenderedOption(renderOption(element), i);
                    })}
            </>
        );
    };

    return (
        <>
            <Box
                ref={containers}
                sx={{
                    padding: "2%",
                    margin: "2%",
                    background: "white",
                    width: "80%",
                    height: "100%",
                    overflowY: "auto",
                    overflowX: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: "0.5rem",
                    }}
                >
                    {title && (
                        <Typography style={{ fontWeight: "bold", fontSize: "12px !important" }}>{title}</Typography>
                    )}
                    <Button
                        onClick={() => onSaveHandler()}
                        sx={{
                            display: !saveButton ? "none" : "block",
                            color: "black",
                        }}
                        style={{ color: "black" }}
                    >
                        <Grid container item justifyContent="center" flexWrap={"nowrap"}>
                            <SaveIcon />
                            <Typography paddingTop="2px">Guardar cambios</Typography>
                        </Grid>
                    </Button>
                </Box>
                {sortSection()}
            </Box>
        </>
    );
}
