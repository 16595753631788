import React, { useState } from 'react'
import { Grid, Input, Typography, TextField } from '@mui/material'
import CustomDivider from '../../../shared/Components/CustomDivider/CustomDivider'

import theme from '../../../css/Theme'

export default function BatchForm({ onFormChange }) {
    const [formValues, setFormValues] = useState({
        title: '',
        body: ''
    })

    const onChangeInput = (key) => (e) => {
        const copy = { ...formValues, [key]: e.target.value }
        setFormValues(copy)
        onFormChange(copy)
    }

    const fields = [["Titulo", "title"], ["Cuerpo", "body", { textarea: true }]]

    return <>
        <Grid direction="row" container item justifyContent={'center'} gap={4} sx={{ my: '10px' }}>
            <Grid item xs={4}>
                <Typography align="right" color={'red'} fontWeight={800} sx={{ fontSize: '15px' }}>
                    Formulario BATCH
                </Typography>
            </Grid>
        </Grid>
        <Grid direction="row" justifyContent={'center'} alignItems={'center'} gap={4} container item sx={{ my: '10px' }}>

            {fields.map(([label, key, config], i) =>
                <>
                    <Grid item xs={2}>
                        <Typography align="left" fontWeight={900} sx={{ fontSize: '15px' }} color={theme.palette.darkerGray.main}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        {!config &&
                            <Input
                                onChange={onChangeInput(key)}
                                sx={{ width: "100%" }}
                                type="textarea"
                            />
                        }
                        {config && config.textarea &&
                            <TextField
                                multiline sx={{ width: "100%" }}
                                onChange={onChangeInput(key)}
                            />
                        }
                    </Grid>
                </>
            )}
        </Grid>
        <Grid container item xs={1} justifyContent={'center'} direction="column" sx={{ height: '100%' }}>
            <CustomDivider />
        </Grid>
    </>
}
