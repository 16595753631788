import { Grid, Typography } from "@mui/material";
import { ThemedButton } from "../../../shared/Components/CustomButton/CustomButton";
import CustomTextField from "../../../shared/Components/CustomTextField/CustomTextField";
import { useCallback, useEffect, useState } from "react";
import theme from "../../../css/Theme";
import sedeSubject from "../../../subjects/SedeSubject";
import ZonaSelect from "./ZonaSelector";
import ErrorMessage from "../../UI/ErrorMessage";
import UploadImg from '../../../shared/Components/UploadImgComponent/UploadImg';
import InputText from "../../UI/InputLabels";
import EditorHeader from "../../UI/EditorHeader";
import StatusSwitch from "./StatusSwitch";

/**
 * Renders part of the form that creates and saves a a new notification.
 * @returns
 */
const EditControls = () => {
  const [loading, setLoading] = useState(false);
  const [newSalon, setNewSalon] = useState(sedeSubject.newSalon);
  const [ , setTitle] = useState(sedeSubject.newSalon.nombre)
  const [direccionError, setDireccionError] = useState(false);
  const [telefonoError, setTelefonoError] = useState(false);
  const [nombreError, setNombreError] = useState(false);
  const [latitudError, setLatitudError] = useState(false);
  const [longitudError, setLongitudError] = useState(false);
  const [horarioError, setHorarioError] = useState(false);
  const [emailError, setEmailError] = useState(false)
  const [zonaError, setZonaError] = useState(false)
  const [saved, setSaved] = useState(false)
  const [estatus, setEstatus] = useState(sedeSubject.newSalon.estatus);

  useEffect(() => {
    setEstatus(sedeSubject.newSalon.estatus);
  }, [newSalon.estatus]);
  

  useEffect(() => {
    const updateSalon = () => {
      setNewSalon(sedeSubject.newSalon);
      setDireccionError(false);
      setTelefonoError(false);
      setNombreError(false);
      setLatitudError(false);
      setLongitudError(false);
      setHorarioError(false);
      setEmailError(false);
      setZonaError(false);
    };
  
    updateSalon();
  }, [newSalon]);
  

  const toURL = (imageFile) => {
    if (typeof imageFile !== 'string' && imageFile) {
      return URL.createObjectURL(imageFile)
    } else return imageFile
  }

  const resetState = useCallback(() => {
    setTitle(sedeSubject.newSalon.nombre);
  }, []);

  useEffect(() => {
    sedeSubject.addObserver(resetState);
    resetState();
    return () => {
      sedeSubject.removeObserver(resetState);
    };
  }, [resetState]);

  function validateLatitudAndLongitud(field, value) {
    const latitudOrLongitud = parseFloat(value);
    if(field === 'latitud'){
      return !isNaN(latitudOrLongitud) && latitudOrLongitud >= -90 && latitudOrLongitud <= 90;
    } else {
      return !isNaN(latitudOrLongitud) && latitudOrLongitud >= -180 && latitudOrLongitud <= 180;
    }
}

  function validation() {
    const fields = [
        { name: "telefono", errorSetter: setTelefonoError },
        { name: "nombre", errorSetter: setNombreError },
        { name: "direccion", errorSetter: setDireccionError },
        { name: "latitud", errorSetter: setLatitudError },
        { name: "longitud", errorSetter: setLongitudError },
        { name: "schedule", errorSetter: setHorarioError },
        { name: "email", errorSetter: setEmailError },
        { name: "zona", errorSetter: setZonaError }
    ];

    let errors = 0;

    fields.forEach(field => {
        const value = sedeSubject.newSalon[field.name].trim();

        if (field.name === "latitud" || field.name === "longitud") {
            if (!validateLatitudAndLongitud(field.name ,value)) {
              errors++;
              (field.name === "latitud") 
                ? setLatitudError("La latitud debe ser un valor entre -90 y 90") 
                : setLongitudError("La longitud debe ser un valor entre -180 y 180");
              return;
            }
        }

        if(field.name === "nombre" && value.length > 50){
          errors ++
          setNombreError("El nombre supera el límite de caracteres")
          return
        }

        if(field.name === "direccion" && (value.length > 50 || value.length < 5)){
          errors ++
          setDireccionError("La dirección debe contener entre 5 y 50 caracteres")
          return;
        }

        if (value === "" || sedeSubject.newSalon.zona === "Zona") {
          errors++;
          field.errorSetter(true);
        } else {
          field.errorSetter(false);
        }
    });
    return errors === 0;
  }

  const handlePut = async () => {
    setLoading(true);
    var passValidation = validation();
    if(!passValidation){
      setLoading(false);
      alert("La sede no se guardó. Resolver los errores en el formulario")
    }
    else{
      let response = await sedeSubject.putSede(newSalon);
      resetState()
      showAlert(response)
      if (response && response.status !== undefined){
        setTimeout(() => {
          sedeSubject.updateSedesForPut();
          setLoading(false)
        }, 1500);
        setTimeout(() => {
          showAlert(response)
          sedeSubject.cleanSelectedItem();
          sedeSubject.notify();
        }, 2500);
      } else{
        setLoading(false)
      }
    }  
  };

  const showAlert = (response) => {
    if (response && response.status !== undefined) {
      if (response.status === 200) {
        setSaved(true);
      }
    }
  };
  

  const handleCancel = async () =>{
    await sedeSubject.cancelEdition();
  }

  return (
    <>
      <Grid
        container
        item
        xs={11}
        direction="column"
        justifyContent={"flex-start"}
        alignItems={"center"}
        gap={2}
        wrap={"nowrap"}
        sx={{ height: "100%" }}
        style={{ overflow: "auto" }}
      >
        <EditorHeader buttonText={"Editar Sede"} buttonColor="red" />
        {/* <Grid item xs={1} sx={{ width: "100%" }} style={{  marginBottom: "20px" }}>
          <div className="container" style={{ display: "flex", flexDirection:"row", gap:"20px", justifyContent:"space-around" }}>
            {sedeSubject.newSalon.cardImagen &&
              <div className="input-wrapper" style={{ position:"relative" }}>
                <Typography style={{ position:"absolute", top: "-20px" }}>Imagen card</Typography><br></br>
                <img style={{ height:"100px", marginTop: "10px" }} alt="cardimage" src={process.env.REACT_APP_RESOURCES_BASEURL + toURL(sedeSubject.newSalon.cardImagen)}></img>
              </div>
            }
            {sedeSubject.newSalon.imagen &&
              <div className="input-wrapper" style={{ position:"relative" }}>
                <Typography style={{ position:"absolute", top: "-20px" }}>Imagen sede</Typography><br></br>
                <img  style={{ height:"100px", marginTop: "10px" }} alt="detailImage" src={process.env.REACT_APP_RESOURCES_BASEURL + toURL(sedeSubject.newSalon.imagen)}></img>
              </div>
            }
          </div>
        </Grid> */}
        <Grid container direction="row" xs={8} sx={{ width: "100%" }} style={{ marginTop:"1px" , marginBottom: "20px" }}>
          <Grid container item direction="column">
            <Grid item xs={1} style={{ justifyContent: "center" }}>
              <Typography>Imagen card</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="center">
              <UploadImg
                title="Imagen card"
                onChange={(file) => {
                  sedeSubject.selectedImageForClub = file;
                }}
                value={toURL(sedeSubject.newSalon.cardImagen)}
                infoImg={{ format: 'JPG/PNG', resolution: '255 X 280' }}
              />
            </Grid>
          </Grid>
          <Grid container item direction="column">
            <Grid item xs={1} style={{ justifyContent: "center" }}>
              <Typography>Imagen sede</Typography>
            </Grid>
            <Grid item xs={1} justifyContent="center">
              <UploadImg
                title="Imagen sede"
                value={toURL(sedeSubject.newSalon.imagen)}
                onChange={(file) => {
                  sedeSubject.seletedDetailImageForClub = file;
                }}
                infoImg={{ format: 'JPG/PNG', resolution: '430 X 250' }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"-10px" }}>
          <InputText label="Nombre:" gridProps={{ style: { marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Nombre (máx. 50 caract.)"}
            backgroundcolor={nombreError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue={sedeSubject.newSalon.nombre}
            onChange={(e) => {
              sedeSubject.newSalon.nombre = e.target.value;
            }}
          />
        </Grid>
        {nombreError && (
          <ErrorMessage message={nombreError === true ? "El campo no puede estar vacío" : nombreError} marginTop={"20px"} marginBottom={"-40px"} />
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"10px" }}>
          <InputText label="Dirección:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Dirección"}
            backgroundcolor={direccionError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.direccion
            }
            border={"red"}
            onChange={(e) => {
              sedeSubject.newSalon.direccion = e.target.value;
            }}
          />
        </Grid>
        {direccionError && (
          <ErrorMessage message={direccionError === true ? "El campo no puede estar vacío" : direccionError} marginTop="40px" marginBottom="-55px" />
        )}  
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <InputText label="Latitud:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Latitud"}
            backgroundcolor={latitudError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.latitud
            }
            onChange={(e) => {
              sedeSubject.newSalon.latitud = e.target.value;
            }}
          />
        </Grid>
        {latitudError && (
          <ErrorMessage message={latitudError} marginTop="40px" marginBottom="-55px" />
        )} 
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <InputText label="Longitud:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Longitud"}
            backgroundcolor={longitudError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.longitud
            }
            onChange={(e) => {
              sedeSubject.newSalon.longitud = e.target.value;
            }}
          />
        </Grid>
        {longitudError && (
          <ErrorMessage message={longitudError} marginTop="40px" marginBottom="-55px" />
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <InputText label="Zona:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <ZonaSelect
            zonaError={zonaError}
            onChange={(e) => {
              sedeSubject.newSalon.zona = e.target.value;
            }}
            defaultValue={sedeSubject.newSalon.zona || "Zona"}
          />
        </Grid>
        {zonaError && (
          <ErrorMessage message={"Selecciona una zona"} marginTop="40px" marginBottom="-55px" />
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"25px" }}>
          <InputText label="Horarios:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Horarios"}
            backgroundcolor={horarioError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.schedule
            }
            onChange={(e) => {
              sedeSubject.newSalon.schedule = e.target.value;
            }}
          />
        </Grid>
        {horarioError && (
          <ErrorMessage message={"El campo no puede estar vacio"} marginTop="40px" marginBottom="-55px" />
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"25px" }}>
          <InputText label="Correo:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Correo"}
            backgroundcolor={emailError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.email
            }
            onChange={(e) => {
              sedeSubject.newSalon.email = e.target.value;
            }}
          />
        </Grid>
        {emailError && (
          <ErrorMessage message={"El campo no puede estar vacio"} marginTop="40px" marginBottom="-55px" />
        )}
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <InputText label="Teléfono:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Teléfono"}
            backgroundcolor={telefonoError ? "red" : "white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.telefono
            }
            onChange={(e) => {
              sedeSubject.newSalon.telefono = e.target.value;
            }}
          />
        </Grid>
        {telefonoError && (
          <ErrorMessage message={"El campo no puede estar vacio"} marginTop="40px" marginBottom="-55px" />
        )}  
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"28px" }}>
          <InputText label="Texto descarga de grilla:" gridProps={{ style: { marginTop: "20px", marginBottom: "5px" } }} />
          <CustomTextField
            placeholder={"Texto descarga de grilla"}
            backgroundcolor={"white"}
            background={theme.palette.lightGray.main}
            defaultValue= {
              sedeSubject.newSalon.textoDescargaGrilla
            }
            onChange={(e) => {
              sedeSubject.newSalon.textoDescargaGrilla = e.target.value;
            }}
          />
        </Grid>
        <Grid item xs={1} sx={{ width: "100%", height:"22px", marginTop:"35px" }}>
          <Grid container item xs={3} style={{ marginTop: "20px", marginBottom: "5px" }}>
          </Grid>
          <Grid item xs={3} style={{ marginLeft: "33%" }}>
            <Typography style={{ textAlign:"center" }}>Activo</Typography>
            <StatusSwitch
              sx={{ m: 1 }}
              marginBottom="30px"
              checked={estatus === 1}
              onChange={(e) => {
                const newEstatus = e.target.checked ? 1 : 0;
                setEstatus(newEstatus);
                sedeSubject.newSalon.estatus = newEstatus;
              }}
            />
          </Grid>
        </Grid>
      </Grid>
                
      <Grid item xs={1}>
      {!saved ? (
        <ThemedButton handler={!loading ? handlePut : null}>
          {!loading ? "GUARDAR" : "GUARDANDO..."}
        </ThemedButton>
        ) : (
          <h4 className="status-active">La sede se guardó correctamente</h4>
        )}
      </Grid>

      {!saved && (
        <Grid item xs={1} sx={{ mt: 1 }}>
          <ThemedButton
            handler={() => handleCancel()}
            background="transparent"
            border="2px solid red"
            color={"red"}
          >
            CANCELAR
          </ThemedButton>
        </Grid>
      )}
    </>
  );
};
export default EditControls;