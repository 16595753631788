import clubService from "../http/services/ClubService"
import classService from "../http/services/ClassService";
import { SedeTemplate } from "./models/SedeTemplate";
import appService from "../http/services/AppService";
import { contentPageActionConfig } from "./models/ActionModels";

class SedeSubject {
  names = [];
  selectedPage = "viewer";
  selectedType = "BATCH";
  categories = [];
  observers = [];
  selectedName = "";
  newSede = new SedeTemplate();
  clubSelected = new contentPageActionConfig({});
  filteredSedes = [...this.names];
  selectedImageForClub = "";
  seletedDetailImageForClub = "";

  /**
   * Gets all the notifications and categories available in the DB. Then, updates the local variables
   */
  async updateSedesForPut() {
    this.names = await classService.getClubs();
    this.filteredSedes = [...this.names.data.resultado];
  }

  async updateSedesForCancel() {
    this.names = await classService.getClubs();
    this.filteredSedes = [...this.names.data.resultado];
    this.notify();
  }
  
  /**
   * Updates the local type variable.
   * @param {String} type type of notification: could be batch or custom
   */
  setSelectedType(type) {
    this.selectedType = type;
    this.notify();
  }
  /**
   * Updates the local page variable
   * @param {String} page that is currently rendered: colud be viewer or create
   */
  setSelectedPage(page) {
    this.selectedPage = page;
    this.updateSedesForCancel();
    this.notify();
  }
  /**
   * Updates the local name variable
   * @param {String} page that is currently rendered: colud be viewer or create
   */
  setSelectedName(room) {
    this.newSalon = room;
    this.notify();
  }

  addObserver(observer) {
    this.observers.push(observer);
  }

  /**
   *  Adds an observer function to the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  addObservers(o) {
    this.observers.push(o);
  }

  removeObserver(observer) {
    let index = this.observers.indexOf(observer);
    this.observers.splice(index, 1);
  }
  /**
   * Removes an observer function from the array of observers
   * @param {Function} o observer function created in the push notification viewer component
   */
  removeObservers(o) {
    let index = this.observers.indexOf(o);
    this.observers.splice(index, 1);
  }
  /**
   *  Runs all the observer functions in the array of observers.
   */
  notify() {
    this.observers.forEach((element) => element());
  }
  /**
   * Creates a new instance on the NotificationPushTemplate and setal all the local variables to their default value.
   */
  async cleanSelectedItem() {
    this.newSede = new SedeTemplate();
    this.setSelectedType(null);
    this.setSelectedPage("viewer");
  }

  /**
   * Filters the array of notificationsPush by name. If the input value is empty it sets the filterd array to equal the notificationsPush array
   * @param {Input event} e
   */
  searchHandler(e) {
    let arr = [...this.names.data.resultado];
    let inputText = e.target.value.toUpperCase();
    let filtered = arr.filter((elem) => {
      let name = elem.nombre.toUpperCase();
      return name.includes(inputText) && elem.estatus === 1;
    });
    this.filteredSedes = [...filtered];
    if (e.target.value === "") {
      this.filteredSedes = arr;
    }
    this.notify();
  }

  /**
   * Checks if all the required inputs of the create notification form are filled.
   * Adds the new notification instance to the array of notifications push, and to the array of filtered notifications push.
   */

  async putSede(room) {
    if (room === "") {
      alert("El nombre no puede ser vacío");
    } else {
      let arr;
      if (this.names.data && this.names.data.resultado) {
        arr = [...this.names.data.resultado];
      } else {
        arr = [...this.names];
      }

      const requestBody = new SedeTemplate(room);
      let find = arr.find((element) => element.id === requestBody.id);
      let index = arr.indexOf(find);
      let jsonResponse;

      var saveImageCardResponse;
      var saveImageResponse;
      
      if(this.selectedImageForClub){
        if(this.selectedImageForClub.size > 500000){
          alert("LA IMAGEN CARD EXCEDE EL TAMAÑO PERMITIDO DE 500KB.")
          return 500
        }
        saveImageCardResponse = this.saveClubImage(this.selectedImageForClub)
        requestBody.cardImagen = this.selectedImageForClub.name;
      }
      
      if(this.seletedDetailImageForClub){
        if(this.seletedDetailImageForClub.size > 500000){
          alert("LA IMAGEN SEDE EXCEDE EL TAMAÑO PERMITIDO DE 500KB.")
          return 500
        }
        saveImageCardResponse = this.saveClubImage(this.seletedDetailImageForClub)
        requestBody.imagen = this.seletedDetailImageForClub.name;
      }

      const isImageSaved = (response) => response && response.status === 200;

      if (isImageSaved(saveImageCardResponse) || isImageSaved(saveImageResponse)) {
        requestBody.cardImagen = !requestBody.cardImagen ? room.cardImagen : requestBody.cardImagen;
        requestBody.imagen = !requestBody.imagen ? room.imagen : requestBody.imagen;
      }

      try {
        jsonResponse = await clubService.put(requestBody);
      } catch (error) {
        console.log(error);
      }

      jsonResponse && (arr[index] = new SedeTemplate(jsonResponse.data[0]));
      this.selectedPage = "viewer";
      return jsonResponse;
    }
  }

  async saveClubImage(selectedImageForClub){
    await appService.saveClubImage(
      selectedImageForClub
    );
  }
  
  cancelEdition(){
    this.updateSedesForCancel();
    this.cleanSelectedItem();
      this.notify();
  }
}
const sedeSubject = new SedeSubject();
export default sedeSubject;
