import React from 'react';
import { MenuItem } from '@mui/material';
import CustomSelect from "../../../shared/Components/CustomSelect/CustomSelect";

const ZonaSelect = ({ zonaError, onChange, defaultValue }) => {
  return (
    <CustomSelect
      label="Zona"
      defaultValue={defaultValue}
      onChange={onChange}
      border={zonaError ? "2px solid red" : "2px solid white"}
    >
      <MenuItem value={"Zona"} sx={{ color: 'darkerGray.main' }} disabled>
        Zona
      </MenuItem>
      <MenuItem value={"BUENOS AIRES"} sx={{ color: 'darkerGray.main' }}>
        Buenos Aires
      </MenuItem>
      <MenuItem value={"CABA"} sx={{ color: 'darkerGray.main' }}>
        CABA
      </MenuItem>
      <MenuItem value={"CORDOBA"} sx={{ color: 'darkerGray.main' }}>
        Córdoba
      </MenuItem>
      <MenuItem value={"SANTA FE"} sx={{ color: 'darkerGray.main' }}>
        Santa Fe
      </MenuItem>
    </CustomSelect>
  );
};

export default ZonaSelect;
